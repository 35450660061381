<template>
  <div class="allProjects">
    <div v-if="modal" class="dialog project-to-publish">
      <div class="dialog-content">
        <div class="dialog-header">
          <div class="close" @click="modal = false">
            <svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
              <path fill="currentColor" d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
            </svg>
          </div>
          <div class="title">
            <span v-if="!projectToPublish.published">Sei sicuro di voler pubblicare il progetto "{{ projectToPublish.name }}"?</span>
            <span v-else>Sei sicuro di voler nascondere il progetto "{{ projectToPublish.name }}"?</span>
          </div>
        </div>
        <div class="dialog-image" :class="!projectToPublish.published ? 'upload' : 'secret'">
          <img v-if="!projectToPublish.published" src="../assets/upload.png">
          <img v-else src="../assets/top-secret.png">
        </div>
        <div class="dialog-text">
          <span v-if="!projectToPublish.published">
            Con la pubblicazione, il progetto sarà visibile su pagine dedicate su Webflow e sarà possibile estrarre le candidature associate per l'utilizzo nelle campagne di marketing.
          </span>
          <span v-else>
            Nascondendo il progetto, non sarà più visibile su pagine dedicate su Webflow e non sarà possibile estrarre le candidature associate per l'utilizzo nelle campagne di marketing.
          </span>
        </div>
        <div class="dialog-footer">
          <material-button :text="!projectToPublish.published ? 'Pubblica progetto' : 'Nascondi progetto'" :type="!projectToPublish.published ? 'info' : 'error'" @click="publishProject()" />
        </div>
      </div>
    </div>
    <div style="width:100%; height:100%">
      <div class="filters">
        <div class="search">
          <input id="search" v-model="filters.search" type="text" name="" placeholder="Cerca progetto per id o nome...">
        </div>

        <div class="lens">
          <font-awesome-icon icon="magnifying-glass" class="fa-lg" />
        </div>
      </div>

      <div id="allProjectTable" ref="allProjectTable" class="table">
        <table id="mainTable" cellspacing="0">
          <thead>
            <tr>
              <th v-for="(header, index) of mainHeaders" :key="header" :class="[{ 'orderable': filters.order.field == header }, { 'published_project': index === 3 }]" @click="changeOrder(header)">
                <div>
                  <span>{{ getHeader(header) }}</span>
                  <font-awesome-icon :class="{ 'active': filters.order.field === header }" :icon="header !== 'id' ? (filters.order.asc ? 'arrow-down-a-z' : 'arrow-down-z-a') : (filters.order.asc ? 'arrow-down-1-9' : 'arrow-down-9-1')" />
                </div>
              </th>
              <th />
            </tr>
          </thead>

          <tbody :key="'table_' + paginateResults.length" class="table-body">
            <slot v-for="(project, index) in paginateResults" :key="'main_' + index">
              <tr :id="'tr_' + index" class="cursor-pointer" :class="{ 'opacityTr': projectSelected && projectSelected.id && project.id != projectSelected.id }" @click="toggleMasterDetail('main_' + index, project, index)">
                <td class="published_project">
                  <div v-if="project.published" class="published" />
                  <div v-else class="notpublished" />
                </td>
                <td>
                  {{ project.id }}
                </td>
                <td>
                  {{ project.name }}
                </td>
                <td>{{ dayjs(project.creationTs).format('DD/MM/YYYY') }}</td>

                <td @click.stop.prevent="showModal(project)">
                  <material-button class="x-small" :text="!project.published ? 'Pubblica progetto' : 'Nascondi progetto'" :type="!project.published ? 'info' : 'error'" />
                  <div v-if="false" class="switch-container">
                    <span class="switch">
                      <input v-model="project.published" type="checkbox" :checked="project.published">
                      <div class="slider round" />
                    </span>
                    <span v-if="false && project.published"> Pubblicato! </span>
                  </div>
                </td>

                <td class="chevron" style="width: 50px">
                  <svg :class="{ rotate: collapsiblePanelOpened === 'main_' + index }" style="width: 24px; height: 24px" viewBox="0 0 24 24">
                    <path fill="currentColor" d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z" />
                  </svg>
                </td>
              </tr>

              <tr v-if="collapsiblePanelOpened === 'main_' + index && projectSelected.projectName[0]" class="collapsible-content">
                <td colspan="6" class="content-inner">
                  <div class="table-container">
                    <project :is-edit="true" :project-selected="projectSelected" @modifyProject="modifyProject($event)" @deleteProject="deleteProject" />
                  </div>
                </td>
              </tr>
            </slot>
          </tbody>
        </table>
      </div>
    </div>
    <div v-if="totalPages > 1" class="vpagination">
      <span>{{ getPageLabel }} </span>
      <v-pagination v-model="currentPage" :pages="totalPages" active-color="#bcde42" @update:modelValue="currentPage = $event" />
    </div>
  </div>
</template>

<script>
import VPagination from '@hennge/vue3-pagination'
import '@hennge/vue3-pagination/dist/vue3-pagination.css'
import project from './Project.vue'
import { sbapibackoffice } from '@/api'
import { navbarStore } from '@/stores/navbar'
import { spinnerStore } from '@/stores/spinner'
import dayjs from 'dayjs'
dayjs.locale('it')
import { watch, ref, inject, onBeforeMount, onMounted, toRefs, computed, reactive } from 'vue'
import '@vuepic/vue-datepicker/dist/main.css'
require('dayjs/locale/it')
var utc = require('dayjs/plugin/utc')
dayjs.extend(utc)
export default {
  components: { project, VPagination },

  setup() {
    const allProjectTable = ref(null)
    const toast = inject('$toast')
    const spinner = spinnerStore()
    const navbar = navbarStore()
    const state = reactive({
      projectToPublish: {},
      modal: false,
      tags: [],
      projectSelected: {},
      allProjects: [],
      searchQuery: '',
      currentPage: 1,
      itemsPerPage: 5,
      mainHeaders: [
        'published',
        'id',
        'name',
        'creationTs',
        ''
      ],
      filters: {
        order: {
          field: 'creationTs',
          asc: false
        },
        search: ''
      },
      project: [],
      dayjs,
      collapsiblePanelOpened: null
    })

    onMounted(() => {
      spinner.show()
      navbar.title = 'Tutti i progetti'
      spinner.hide()
    })

    onBeforeMount(async () => {
      navbar.title = 'Tutti i progetti'
      //state.allProjects = generateProjectsArray(50)
      spinner.show()
      try {
        const response = await sbapibackoffice.get('/projects?page=0&size=10&sort=id,desc')
        if (response?.data?.content) {
          state.allProjects = response.data.content
          toast.success(response?.data?.content?.length + ' progetti trovati')
          console.log('ok', response?.data?.content)
        }
      } catch (err) {
        toast.clear()
        toast.error('Nessun progetto trovato')
      } finally {
        spinner.hide()
      }
    })
    // Funzione per calcolare il numero di elementi per pagina
    const calculateElementsPerPage = () => {
      const parentHeight = allProjectTable.value.parentElement.clientHeight
      if (parentHeight) {
        const headerHeight = 130 // Altezza della header
        const footerHeight = 50 // Altezza della footer
        const singleElementHeight = 60 // Altezza di un singolo elemento
        const availableHeight = parseFloat(parentHeight) - headerHeight - footerHeight // Altezza disponibile per la tabella
        state.itemsPerPage = Math.floor(availableHeight / singleElementHeight) // Numero di elementi da visualizzare per pagina
        console.log('Numero di elementi', state.itemsPerPage)
      }
    }
    watch(allProjectTable, (newVal) => {
      // Logica da eseguire quando la variabile allProjectTable cambia
      if (newVal) {
        calculateElementsPerPage()
      }
    })
    // Ascolta l'evento di ridimensionamento della finestra
    watch(() => {
      window.addEventListener('resize', calculateElementsPerPage)
    })
    /*     const generateProjectsArray =(numProjects)=> {
      const projectsArray = []

      function generateRandomProjectName() {
        const adjectives = ['Grande', 'Piccolo', 'Rapido', 'Lento', 'Agile', 'Solido', 'Innovativo']
        const nouns = ['Progetto', 'Applicazione', 'Software', 'Sistema', 'Piattaforma']

        const randomAdjective = adjectives[Math.floor(Math.random() * adjectives.length)]
        const randomNoun = nouns[Math.floor(Math.random() * nouns.length)]

        return `${randomAdjective} ${randomNoun}`
      }

      for (let i = 1; i <= numProjects; i++) {
        const randomTimestamp = Math.random() * (new Date().getTime())
        const project = {
          published: Math.random() < 0.5,
          id: i,
          name: generateRandomProjectName(),
          creationTs: new Date(randomTimestamp)
        }

        projectsArray.push(project)
      }

      return projectsArray
    } */
    const toggleMasterDetail = async (value, project, index) => {
      if (state.collapsiblePanelOpened === value) {
        state.collapsiblePanelOpened = null
        state.projectSelected = {}
      }
      else {
        spinner.show()
        await sbapibackoffice.get('/projects/' + project.id).then(res => {
          state.projectSelected.id = project.id
          state.projectSelected.projectName = [res.data.name]
          state.tags = [res.data.name]
          state.projectSelected.startDate = new Date(res.data.startDate)
          state.projectSelected.endDate = new Date(res.data.endDate)
          state.projectSelected.published = res.data.published
          state.projectSelected.companies = res.data.projectCampaigns.map(item => {
            const { title, companyName, ...rest } = item.campaign
            return {
              ...rest,
              titolo: title,
              company: companyName,
              deactivated: item.deactivated,
              delete: false
            }
          })
          // state.projectSelected.companiesExcluded = res.data.projectCompanies[0]?.excludeCampaigns ?? []

          // if (state.projectSelected.companiesExcluded.length === 0 && res.data.projectCompanies.length) {
          //   state.projectSelected.companiesExcluded = [
          //     res.data.projectCompanies.companies
          //   ]
          // }
          state.collapsiblePanelOpened = value

          state.projectSelected.projectCompanies = res.data.projectCompanies.map((item) => 
            ({
              coid: item.coid,
              companyName: item.companies.coName,
              excludeCampaigns: item.excludeCampaigns.map((item2) => 
                ({
                  cid: item2.cid,
                  title: item2.title,
                  titolo: item2.title,
                  coid: item2.coid,
                  company: item.companies.coName,
                  status: item2.status
                })
              )
            })
          )
        })
          .catch(err => {
            toast.error('Impossibile ottenere il dettaglio del progetto: ' + err)
          })
          .finally(() => {
            let tr = document.getElementById('tr_' + index)
            tr.scrollIntoView({ behavior: 'smooth' })
            spinner.hide()
          })
      }
    }
    const getFilteredRows = computed(() => {
      const searchQuery = state.filters.search.toLowerCase().trim()

      let filteredProjects = [...state.allProjects]

      // Applica il filtro di ricerca
      filteredProjects = filteredProjects.filter((project) => {
        const name = project.name.toLowerCase()
        const id = project.id.toString()

        return name.includes(searchQuery) || id.includes(searchQuery)
      })

      // Applica l'ordinamento
      const { field, asc } = state.filters.order
      filteredProjects.sort((a, b) => {
        const fieldA = a[field]
        const fieldB = b[field]

        let comparison = 0
        if (fieldA > fieldB) {
          comparison = 1
        } else if (fieldA < fieldB) {
          comparison = -1
        }

        return asc ? comparison : -comparison
      })
      return filteredProjects

    })
    const paginateResults = computed(() => {
      const startIndex = (state.currentPage - 1) * state.itemsPerPage
      const endIndex = startIndex + state.itemsPerPage
      return getFilteredRows.value.slice(startIndex, endIndex)
    })

    const removeItem = index => {
      state.projectSelected.companies.splice(index, 1)
    }
    const showModal = (project) => {
      state.projectToPublish = project
      state.modal = true
    }
    const publishProject = async () => {
      spinner.show()
      sbapibackoffice
        .put('projects/' + state.projectToPublish.id + '/status', {
          'published': !state.projectToPublish.published
        })
        .then(response => {
          console.log(response)
          const index = state.allProjects.findIndex(project => project.id === response.data.id)
          if (index !== -1) {
            state.allProjects[index].published = response.data.published
          }
          toast.success('Progetto aggiornato con successo!')
        })
        .catch(err => {
          if (err.response && err.response.data) {
            let messageCode = err.response.data.messageCode
            let errorMessage = 'Si è verificato un errore: '
            if (err.response.status === 406) {
              switch (messageCode) {
                case 'PRJ-007':
                  errorMessage += 'Progetto non trovato per id: ' + state.projectToPublish.id
                  break
                case 'PRJ-008':
                  errorMessage += 'Non è possibile aggiornare lo stato del progetto in quanto è già nello stato richiesto in input'
                  break
                case 'PRJ-009':
                  errorMessage += 'Il campo in input deve essere valorizzato. published = ' + state.projectToPublish.published
                  break
              }
            }
            toast.error(errorMessage)
          } else {
            toast.error('Si è verificato un errore')
          }
        })
        .finally(() => {
          state.projectToPublish = {}
          state.modal = false
          spinner.hide()
        })
    }
    const deleteProject = () => {
      spinner.show()
      sbapibackoffice
        .delete('projects/' + state.projectSelected.id)
        .then(() => {
          const index = state.allProjects.findIndex(project => project.id === state.projectSelected.id)
          if (index !== -1) {
            state.allProjects.splice(index, 1)
          }
          toast.success('Progetto cancellato con successo!')
        })
        .catch(err => {
          if (err.response && err.response.data) {
            let messageCode = err.response.data.messageCode
            let errorMessage = 'Si è verificato un errore: '
            if (err.response.status === 406) {
              switch (messageCode) {
                case 'PRJ-007':
                  errorMessage += 'Progetto non trovato per id: ' + state.projectToPublish.id
                  break
              }
            }
            toast.error(errorMessage)
          } else {
            toast.error('Si è verificato un errore')
          }
        })
        .finally(() => {
          state.collapsiblePanelOpened = null
          state.projectSelected = {}
          scrollToTop()
          spinner.hide()
        })
    }
    const scrollToTop = () => {
      if (allProjectTable.value) {
        allProjectTable.value.scrollTop = 0
      }
    }
    const modifyProject = (updateProject) => {
      let params = {
        'name': updateProject.projectName[0],
        'startDate': dayjs(updateProject.startDate).format('YYYY-MM-DD'),
        'endDate': dayjs(updateProject.endDate).format('YYYY-MM-DD'),
        'projectCampaigns': updateProject.companies.filter(p => !p.delete).map(item => ({ 'cid': item.cid, 'deactivated': item.deactivated })),
        'projectCompanies': updateProject.projectCompanies.map(item => ({ 'coid': item.coid, 'excludeCids': item.excludeCampaigns.map(item => item.cid) }))
      }
      
      // if params companies excludeCids contains same cid of params cids toast error
      let cids = params.projectCampaigns.map(item => item.cid)
      let excludeCids = params.projectCompanies.map(item => item.excludeCids).flat()
      let duplicateCids = cids.filter(cid => excludeCids.includes(cid))
      if (duplicateCids.length > 0) {
        toast.error('Sono presenti cid in comune tra quelli da aggiungere e escludere dal progetto')
        return
      }
      
      spinner.show()
      sbapibackoffice
        .put('projects/' + state.projectSelected.id, params)
        .then(response => {
          console.log(response)
          const index = state.allProjects.findIndex(project => project.id === response.data.id)
          if (index !== -1) {
            state.allProjects[index] = response.data
          }
          toast.success('Progetto aggiornato con successo!')

          state.collapsiblePanelOpened = null
          state.projectSelected = {}
          updateProject = {}
          scrollToTop()
        })
        .catch(err => {
          if (err.response && err.response.data) {
            let messageCode = err.response.data.messageCode
            let errorMessage = 'Si è verificato un errore: '
            if (err.response.status === 406) {
              switch (messageCode) {
                case 'PRJ-004':
                  errorMessage += 'La data di inizio deve essere minore della data di fine progetto'
                  break
                case 'PRJ-005':
                  errorMessage += 'Esiste già un progetto con il nome indicato: ' + updateProject.projectName[0]
                  break
                case 'PRJ-006':
                  errorMessage += 'Alcuni dei cid da assegnare al progetto non esistono'
                  break
                case 'PRJ-007':
                  errorMessage += 'Progetto non trovato per id: ' + updateProject.id
                  break
                case 'PRJ-009':
                  errorMessage += 'Sono presenti campagne (cid) duplicate in input'
                  break
                case 'PRJ-010':
                  errorMessage += 'sono presenti aziende (coid) duplicate in input'
                  break
                case 'PRJ-011':
                  errorMessage += 'sono presenti cid in comune tra quelli da aggiungere e escludere dal progetto'
                  break
                case 'PRJ-012':
                  errorMessage += 'alcuni dei coid da assegnare al progetto non esistono'
                  break
                case 'PRJ-013':
                  errorMessage += 'alcuni dei cid da escludere dall\'azienda legata al progetto non esistono'
                  break
              }
            }
            toast.error(errorMessage)
          } else {
            toast.error('Si è verificato un errore')
          }
        })
        .finally(() => {
          spinner.hide()
        })
    }
    const changeOrder = (column) => {
      if (state.filters.order.field === column) {
        state.filters.order.asc = !state.filters.order.asc
      } else {
        state.filters.order.field = column
        state.filters.order.asc = true
      }
    }

    const getHeader = (header) => {
      switch (header) {
        case 'name':
          return 'Progetto'
        case 'creationTs':
          return 'Data creazione'
        case 'published':
          return 'Stato'
        case 'id':
          return 'Id'
        default:
          return ''
      }
    }

    const totalPages = computed(() => {
      return Math.ceil(getFilteredRows.value.length / state.itemsPerPage)
    })
    const formatDisplayString = (pageNumber, itemsPerPage, totalElements) => {
      console.log('tot', totalElements)
      // Calcola l'indice di inizio e fine degli elementi da visualizzare
      const startIndex = (pageNumber - 1) * itemsPerPage + 1
      let endIndex = startIndex + itemsPerPage - 1
      endIndex = Math.min(endIndex, totalElements)
      console.log('tot 2', totalElements)
      // Costruisci la stringa di visualizzazione
      const displayString = `${startIndex} - ${endIndex} di ${totalElements}`
      return displayString
    }
    const getPageLabel = computed(() => {
      return formatDisplayString(state.currentPage, state.itemsPerPage, getFilteredRows.value.length)
    })

    return { ...toRefs(state), getPageLabel, totalPages, getHeader, changeOrder, allProjectTable, modifyProject, deleteProject, publishProject, showModal, removeItem, paginateResults, getFilteredRows, toggleMasterDetail }
  }
}
</script>
<style lang="scss" scoped>
@use '../assets/scss/dialog.scss'; // general dialog styles
@use "../assets/scss/table";
@use "../assets/scss/projects";
</style>
<style lang="scss">
#allProjectTable {
  max-height: 70vh;
}

.table,
table {
   /* Aggiungi overflow-x per visualizzare la scrollbar solo se necessario */
  overflow-x: auto;

  &::-moz-focus-inner {
    border: 0;
    /* Rimuovi il bordo focus per Firefox */
  }

  &::-webkit-scrollbar {
    width: 2.5px;
    /* Imposta la larghezza della scrollbar a 2.5px */
    height: 2.5px;
    /* height of horizontal scrollbar ← You're missing this */
    /* width of vertical scrollbar */
    border: 0px solid #eef8fe;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #1a73a3;
    /* Colore del thumb */
  }

  &::-webkit-scrollbar-horizontal {
    height: 2.5px;
    /* Imposta l'altezza della scrollbar orizzontale a 2.5px */
  }

  &::-webkit-scrollbar-thumb:horizontal {
    background-color: #1a73a3;
    /* Colore del thumb della scrollbar orizzontale */
  }
}

.vpagination {
  color: white;
  display: flex;
  justify-content: space-between;
  align-self: center;
  width: 100%;
  padding: 1rem 0;

  li.PaginationControl svg {
    fill: whitesmoke !important;
  }

  ul.Pagination {
    justify-content: flex-end;

    li.PaginationControl>path {
      fill: rgba(255, 255, 255, 0.233);
    }

    .Control-active {
      fill: whitesmoke !important;
    }

    .Page {
      width: 25px;
      height: 25px;
      color: whitesmoke !important;
    }

    button.Page-active {
      background: var(--accented) !important;
      border: 1px solid var(--accented) !important;
      color: #003e73 !important;
      font-size: 14px;
      font-weight: 600;
    }
  }
}
</style>